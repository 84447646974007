@keyframes digital-pulse {
  0%, 40%, 100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1); } }
.rai-digital {
  height: 1em;
  text-align: center; }
  .rai-digital > div {
    display: inline-block;
    margin-right: 0.125em;
    background-color: #727981;
    width: 0.25em;
    height: 100%;
    animation-name: digital-pulse;
    animation-duration: inherit;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite; }
    .rai-digital > div:last-child {
      margin-right: 0; }
